import React, {useEffect, useMemo, useState} from 'react';
import {CartItemDataHook} from '../../CartItem';
import {CounterSize} from 'wix-ui-tpa';
import {Counter, Popover} from 'wix-ui-tpa/cssVars';
import {debounce} from '../../../../../../domain/lib/debounce';
import {getMaxItemQuantity, getIsQuantityErrorNeeded, getIsOutOfStock} from '../../../../../../domain/utils/itemUtils';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {classes} from './Quantity.st.css';
import classNames from 'classnames';
import {LineItemModel} from '../../../../../../domain/models/LineItem.model';

export const Quantity = ({lineItem, className}: {lineItem: LineItemModel; className?: string}) => {
  const {t} = useTranslation();
  const {updateItemQuantity, shouldPresentTooltipWithoutNumber} = useControllerProps().cartStore;
  const debouncedUpdateItemQuantity = useMemo(() => debounce(updateItemQuantity), [updateItemQuantity]);
  const [quantity, setQuantity] = useState<number>(lineItem.quantity);
  const [hasQuantityError, sethasQuantityError] = useState<boolean>(false);
  const handleChange = (value: string) => {
    const updatedValue = +value || 1;

    const isQuantityErrorNeeded = getIsQuantityErrorNeeded(lineItem.quantityAvailable, updatedValue);

    if (updatedValue === quantity) {
      return;
    }

    if (isQuantityErrorNeeded) {
      triggerQuantityErrorIfNeeded();
      return;
    }

    setQuantity(updatedValue);
    void debouncedUpdateItemQuantity(lineItem.id, updatedValue);
  };

  const triggerQuantityErrorIfNeeded = () => {
    sethasQuantityError(true);
    setTimeout(() => {
      sethasQuantityError(false);
    }, 2e3);
  };
  useEffect(
    () => {
      setQuantity(lineItem.quantity);
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [lineItem.quantity]
  );

  return (
    <Popover
      data-hook={CartItemDataHook.QuantityErrorTooltip}
      className={classNames(classes.popover, className)}
      width={165}
      shown={hasQuantityError}
      placement="top">
      <Popover.Element>
        <Counter
          disabled={getIsOutOfStock(lineItem.quantityAvailable)}
          className={classes.quantity}
          size={CounterSize.xSmall}
          error={hasQuantityError}
          value={quantity}
          step={1}
          data-hook={CartItemDataHook.Quantity}
          onChange={handleChange}
          incrementAriaLabel={t('cart.sr.addQty')}
          decrementAriaLabel={t('cart.sr.removeQty')}
          inputAriaLabel={t('cart.sr.chooseQty')}
          aria-label={t('cart.sr.quantity')}
          min={1}
          max={getMaxItemQuantity(lineItem.quantityAvailable)}
        />
      </Popover.Element>
      <Popover.Content>
        <span role="alert">
          {shouldPresentTooltipWithoutNumber
            ? t('cart.errors.item_quantity_exceed_inventory_no_number')
            : t('cart.errors.item_quantity_exceed_inventory', {
                itemsAmount: getMaxItemQuantity(lineItem.quantityAvailable),
              } as object)}
        </span>
      </Popover.Content>
    </Popover>
  );
};
