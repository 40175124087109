import React from 'react';
import {Skeleton} from '../../../../../../common/components/Skeleton/Skeleton';
import {SkeletonSection} from '../../../../../../common/components/Skeleton/SkeletonSection/SkeletonSection';
import {SkeletonRow} from '../../../../../../common/components/Skeleton/SkeletonRow/SkeletonRow';
import {SkeletonLine} from '../../../../../../common/components/Skeleton/SkeletonLine/SkeletonLine';
import {classes} from './FooterSkeleton.st.css';
import {FooterDataHooks} from '../Footer';

export const FooterSkeleton = () => (
  <Skeleton dataHook={FooterDataHooks.skeleton} className={classes.footerSkeleton}>
    <SkeletonSection condensed={true}>
      <SkeletonRow>
        <SkeletonLine size="medium" />
        <SkeletonLine size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLine size="xl" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLine size="xl" />
      </SkeletonRow>
    </SkeletonSection>
  </Skeleton>
);
