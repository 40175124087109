import React from 'react';
import {classes, st} from './SkeletonLine.st.css';

interface SkeletonLineProps {
  size: string;
  dataHook?: string;
}

export const SkeletonLine = ({size, dataHook}: SkeletonLineProps) => (
  <div data-hook={dataHook} className={st(classes.skeletonLine, classes[size])}></div>
);
